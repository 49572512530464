import axios from 'axios'

import {
  ACCIDENT_INCIDENT,
  AIR_EVENT,
  AIR_LOSS,
  AIR_CAUSE,
  AIR_PREVENTIVE,
  AIR_CORRECTIVE,
  GET_CURRENCIES,
  ADD_CA_FILE,
  GET_CA_FILES,
  DOWNLOAD_CA_FILES,
  AIR_LOSS_DELETE_PERSONEL,
  DOWNLOAD_CA_FILES_ALL,
  ACCIDENT_INCIDENT_ATTACHMENTS,
  COMPANY_INSTRUCTION,
  SENT_TO_VESSEL,
  VESSEL_INSTRUCTION_LIST,
  VESSEL_INSTRUCTION_RESPONSE,
  COMPANY_INSTRUCTION_CANCEL,
  VESSEL_INSTRUCTION_ADD_FILES,
  VESSEL_INSTRUCTION_FILES,
  VESSEL_INSTRUCTION_VIEW_FILE,
  VESSEL_INSTRUCTION_DELETE_FILE,
  DELETE_CA_FILE,
  CI_SAVE_SUBMIT_VESSEL,
  ADD_LOSS_PERSONNEL,
  PERSONNEL_ATTACHMENTS,
  DELETE_PERSONNEL_ATTACHMENT,
  GET_PERSONNEL_ATTACHMENT,
  DOWNLOAD_CA_REPORT,
  UPDATE_REPORT_STATUS,
  DOWNLOAD_AI_REPORT,
  DOWNLOAD_AI_REPORT_TEMPLATE,
  UPLOAD_AI_REPORT,
  FLEET_CONTACT_LIST,
  DOWNLOAD_PSC_REPORT,
  DOWNLOAD_PSC_REPORT_TEMPLATE,
  GET_KEYS,
  UPLOAD_PSC_REPORT_TEMPLATE,
  GET_ACCIDENT_INCIDENT_BY_EVENT,
  GET_FLEET_CONTACT_LISTS,
  DELETE_FLEET_CONTACT_LIST_BY_NAME,
  UPLOAD_FLEET_CONTACT_LIST,
  FLEET_CONTACT_LIST_DATE_CREATED,
  FLEET_CONTACT_LIST_DATE,
  VESSEL_DETAIL,
  DOWNLOAD_CREW_EXPERIENCE_LIST, ACCIDENT_INCIDENTS_STATUS_HISTORY, PSC_INDEX
} from './ConstantService'
import { GlobalService } from './GlobalService'
import {AlertService} from "@/services/AlertService";
import {UrlService} from "@/services/UrlService";

export const ReportService= {
  async addReport (params) {
    return await axios.post(ACCIDENT_INCIDENT, params).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'ADD REPORT SERVICE')
      return false
    })
  },
  async downloadAccidentIncidentStatusHistory(params) {
    const queryString= UrlService.setQueryString(params)
    return await axios({
      url: ACCIDENT_INCIDENTS_STATUS_HISTORY + queryString,
      method: 'GET',
      responseType: 'blob'
    }).then(response => {
      return response.data ?? false
    }).catch(error => {
      return false
    })
  },
  async getAllReport (params) {
    return await axios.get(ACCIDENT_INCIDENT, {params}).then(response => {
      return response?.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET ALL REPORTS SERVICE')
      return false
    })
  },

  async getAccidentIncidentReport(params) {
    const url = ACCIDENT_INCIDENT + `/${params}`
    return await axios.get(url).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET ACCIDENT / INCIDENT REPORT SERVICE')
      return error?.response?.data
    })
  },

  async updateAccidentIncidentReport(params){
    const url = ACCIDENT_INCIDENT + `/${params.id}`
    return await axios.put(url,params).then(response=>{
      return response;
    }).catch(error => {
      return error.response
    });
  },

  async getCurrencyKeys () {
    return await axios.get(GET_CURRENCIES).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET ALL REPORT SERVICE')
      return false
    })
  },

  async getAccidentIncidentEvent(params) {
     return await axios.get(AIR_EVENT + `/${params.id}`).then(response => {
       return response.data ?? false
     }).catch(error => {
       GlobalService.handleError(error, 'GET ACCIDENT / INCIDENT EVENT SERVICE')
       return false
     });
  },

  async updateAccidentIncidentEvent(params) {
    return await axios.put(AIR_EVENT + `/${params.id}`,params).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'UPDATE ACCIDENT / INCIDENT EVENT  SERVICE')
      return false
    });
  },

  async updateAccidentIncidentLoss(params) {
    return await axios.put(AIR_LOSS + `/${params.id}`,params).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'UPDATE ACCIDENT / INCIDENT LOSS SERVICE')
      return false
    });
  },
  async updateAccidentIncidentCause(params) {
    return await axios.put(AIR_CAUSE + `/${params.id}`,params).then(response=>{
      return response.data ?? false
    }).catch(error =>{
      GlobalService.handleError(error, 'UPDATE ACCIDENT / INCIDENT CAUSE SERVICE')
      return false
    })
  },

  async updateAccidentIncidentPreventive(params) {
    return await axios.put(AIR_PREVENTIVE + `/${params.id}`,params).then(response=>{
      return response.data ?? false
    }).catch(error =>{
      GlobalService.handleError(error, 'UPDATE ACCIDENT / INCIDENT PREVENTIVE SERVICE')
      return false
    })
  },

  async updateAccidentIncidentCorrective(params) {
    return await axios.put(AIR_CORRECTIVE + `/${params.id}`,params).then(response=>{
      return response.data ?? false
    }).catch(error =>{
      GlobalService.handleError(error, 'UDPATE ACCIDENT / INCIDENT CORRECTIVE SERVICE')
      return false
    })
  },

  async deleteLossPersonnel(params){
    return await axios.post(AIR_LOSS_DELETE_PERSONEL,params).then(response=>{
      return response.data ?? false
    }).catch(error =>{
      GlobalService.handleError(error, 'DELETE LOSS PERSONNEL SERVICE')
      return false
    })
  },
  async addCAFiles(params, accidentID){
    const url = ADD_CA_FILE + `/${accidentID}/attachments`
    return await axios.post(url, params).then(response => {
      return response ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'ADD CORRECTIVE ACTION FILES SERVICE')
      return false
    })
  },

  async getCAFiles(accidentID, fileTypeId){
    const url = GET_CA_FILES + `/${accidentID}/${fileTypeId}`
    return await axios.get(url).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET CORRECTIVE ACTION FILES SERVICE')
      return false
    })
  },

  async deleteCAFile(accidentID, fileId){
    const url = DELETE_CA_FILE + `/${accidentID}/${fileId}`
    return await axios.delete(url).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'DELETE CORRECTIVE ACTION FILE SERVICE')
      return false
    })
  },

  async downloadCAFiles(accidentID, fileID){
    const url = DOWNLOAD_CA_FILES + `/${accidentID}/${fileID}`
    return await axios({
      url: url,
      method: 'GET',
    }).then(response => {
      return response ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'DOWNLOAD CORRECTIVE ACTION FILE SERVICE')
      return false
    })
  },
  async downloadCAFileAll(accidentID, caFileType){
    const url = DOWNLOAD_CA_FILES_ALL + `/${accidentID}/${caFileType}`
    return await axios({
      url: url,
      method: 'GET',
      responseType: 'blob'
    }).then(response => {
      return response ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'DOWNLOAD ALL CORRECTIVE ACTION FILE SERVICE')
      return false
    })
  },

  async generateCARReport(accidentID, type = 'xlsx'){
    const date_now = new Date();
    const ts = parseInt(date_now.getTime() / 1000) // timestamp
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone; // timezone

    const url = DOWNLOAD_CA_REPORT + `/${accidentID}?${type}=1&ts=${ts}&tz=${tz}`
    return await axios({
      url: url,
      method: 'GET',
      responseType: 'blob'
    }).then(response => {
      return response ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GENERATE CORRECTIVE ACTION REPORT SERVICE')
      return false
    })
  },

  async importAir(params) {
    return await axios.post(UPLOAD_AI_REPORT, params).then(response => {
      return response.data;
    }).catch(error => {
      GlobalService.handleError(error, 'IMPORT ACCIDENT INCIDENT')
      return error.response.data;
    })
  },

  async importPsc(params) {
    return await GlobalService.ajax('post', UPLOAD_PSC_REPORT_TEMPLATE, params, {source: 'IMPORT PSC REPORT'});
  },

  async addAttachments (params) {
    return await axios.post(ACCIDENT_INCIDENT_ATTACHMENTS, params).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'ADD ATTACHMENTS SERVICE')
      return false
    })
  },
  async deleteAttachment(params){
    return await axios.delete(ACCIDENT_INCIDENT_ATTACHMENTS + `/${params.attachmentId}`).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'DELETE ATTACHMENT SERVICE')
      return false
    })
  },
  async getAccidentIncidentAttachments(params) {
    return await axios.get(ACCIDENT_INCIDENT_ATTACHMENTS +`?id=${params.reportId}&isImage=${params.isImage}`).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET ACCIDENT / INCIDENT ATTACHMENTS SERVICE')
      return false
    })
  },
  async generateAttachment(params){
    return await axios({
      url:ACCIDENT_INCIDENT_ATTACHMENTS + `/${params.id}`,
      method: 'GET',
      responseType: 'blob'
    }).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GENERATE ATTACHMENT SERVICE')
      return false
    })
  },
  async downloadAttachment(params){
    const url=ACCIDENT_INCIDENT_ATTACHMENTS +`/downloadAllAttachments`;
    return await axios.post(url, params).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'DOWNLOAD ALL ATTACHMENT SERVICE')
      return false
    })
  },
 // company instructions

   async getAllAICompanyInstruction(params) {
      return await axios.get(COMPANY_INSTRUCTION,{params}).then(response => {
        return response.data ?? false
      }).catch(error => {
        GlobalService.handleError(error, 'GET ALL COMPANY INSTRUCTION SERVICE')
        return false
      })
   },

   async getAICompanyInstruction(reportId) {
     const url = COMPANY_INSTRUCTION + `/${reportId}`
      return await axios.get(url).then(response => {
        return response.data ?? false
      }).catch(error => {
        GlobalService.handleError(error, 'GET COMPANY INSTRUCTION SERVICE')
        return false
      })
   },

  async updateAiCompanyInstruction(reportId, params){
    const url = COMPANY_INSTRUCTION + `/${params.id}`
    return await axios.put(url,params).then(response => {
      return response.data ?? false
    }).catch( error => {
      GlobalService.handleError(error, 'UPDATE COMPANY INSTRUCTION SERVICE')
      return false
    })
  },

  async addAiCompanyInstruction(params){
    return await axios.post(COMPANY_INSTRUCTION,params).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error,'ADD COMPANY INSTRUCTION SERVICE')
      return false
    })
  },

  async sendVesselCompInstruction(accidentId, params){
    const url = SENT_TO_VESSEL + `/${accidentId}`
    return await axios.post(url,params).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'SEND TO VESSEL SERVICE')
      return false
    })
  },

  async getVesselCompInstructions(params){
    // const url = VESSEL_INSTRUCTION_LIST + `/${statusId}`
    return await axios.get(VESSEL_INSTRUCTION_LIST, {params}).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET VESSEL INSTRUCTIONS LIST SERVICE')
      return false
    })
  },

  async sendVesselResponse(civId, params){
    const url = VESSEL_INSTRUCTION_RESPONSE + `/${civId}`
    return await axios.post(url,params).then(response => {
      return response.data ?? false
    }).catch( error => {
      GlobalService.handleError(error, 'SEND VESSEL REPONSE SERVICE')
      return false
    })
  },
  async cancelCompanyInstruction(civId){
    const url = COMPANY_INSTRUCTION_CANCEL + `/${civId}`
    return await axios.delete(url).then(response => {
      return response.data ?? false
    }).catch( error => {
      GlobalService.handleError(error, 'CANCEL COMPANY INSTRUCTION SERVICE')
      return false
    })
  },
  async removeCompanyInstruction(civId, params){
    const url = COMPANY_INSTRUCTION + `/${civId}`
    return await axios.delete(url).then(response => {
      return response.data ?? false
    }).catch( error => {
      GlobalService.handleError(error, 'DELETE COMPANY INSTRUCTION SERVICE')
      return false
    })
  },

  async saveSubmitCompanyInstruction(params){
    return await axios.post(CI_SAVE_SUBMIT_VESSEL, params).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, ' SAVE AND SUBMIT TO VESSEL FAIL')
      return false
    })
  },

  /*Ci = Company Instruction */
  async addCiVesselFiles(params, airCiVesselId){
    const url = VESSEL_INSTRUCTION_ADD_FILES + `/${airCiVesselId}/files`
    return await axios.post(url, params).then(response => {
      return response ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'ADD COMPANY INSTRUCTION FILES SERVICE')
      return false
    })
  },

  async getCiVesselFiles(airCiVesselId){
    const url = VESSEL_INSTRUCTION_FILES + `/${airCiVesselId}`
    return await axios.get(url).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET COMPANY INSTRUCTION FILES SERVICE')
      return false
    })
  },
  async deleteReport(params){
    return await axios.delete(ACCIDENT_INCIDENT +`/${params.reportId}`,{data:params}).then(response => {
      return response.data ?? false
    }).catch(error => {
      if(error.response.status === 500){
        GlobalService.handleError(error, 'DELETE REPORT SERVICE')
      }
      return error.response.data
    })
  },

  async viewAirCiVesselFile(fileID){
    return await axios.get(VESSEL_INSTRUCTION_VIEW_FILE +`/${fileID}`).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'VIEW COMPANY INSTRUCTION FILE SERVICE')
      return false
    })
  },

  async deleteAirCiVesselFile(fileID){
    return await axios.delete(VESSEL_INSTRUCTION_DELETE_FILE +`/${fileID}`).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'DELETE COMPANY INSTRUCTION FILE SERVICE')
      return false
    })
  },

  async updateReportStatus(params){
    return await axios.post(UPDATE_REPORT_STATUS, params).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'UPDATE REPORT STATUS SERVICE')
      return false
    })
  },
  async addLossPersonnel(params){
    return await axios.post(ADD_LOSS_PERSONNEL, params).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'ADD LOSS PERSONNEL SERVICE')
      return error?.response?.data;
    })
  },

  async getAccidentIncidentLoss(params){
    return await axios.get(AIR_LOSS + `/${params.id}`).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET ACCIDENT INCIDENT LOSS SERVICE')
      return false
    })
  },

  async getPersonnelAttachments(params){
    return await axios.post(PERSONNEL_ATTACHMENTS, params).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET PERSONNEL ATTACHMENTS SERVICE')
      return false
    })
  },

  async deletePersonnelAttachment(params){
    return await axios.post(DELETE_PERSONNEL_ATTACHMENT, params).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'DELETE PERSONNEL ATTACHMENT SERVICE')
      return false
    })
  },

  async getPersonnelAttachment(params){
    return await axios({
      url: GET_PERSONNEL_ATTACHMENT,
      method: 'POST',
      responseType: 'blob',
      data: params
    }).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET PERSONNEL ATTACHMENT SERVICE')
      return false
    })
  },

  async downloadAIReport(params, type = 'xlsx') {
    const date_now = new Date();
    const ts = parseInt(date_now.getTime() / 1000) // timestamp
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone; // timezone

    const uri = DOWNLOAD_AI_REPORT.replace('{report_id}', params.id) + `?${type}=1&ts=${ts}&tz=${tz}`;
    return await axios({
      url: uri,
      method: 'GET',
      // params: params,
      responseType: 'blob'
    })
       // .post(uri, params)
      .then(response => {
        return response ?? false
      }).catch(error => {
        GlobalService.handleError(error, 'DOWNLOAD ACCIDENT INCIDENT REPORT SERVICE')
        return false
      })
  },

  async downloadAIReportTemplate(vessel_id) {
    const date_now = new Date();
    const ts = parseInt(date_now.getTime() / 1000) // timestamp
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone; // timezone

    const uri = DOWNLOAD_AI_REPORT_TEMPLATE.replace('{vessel_id}', vessel_id) + `?ts=${ts}&tz=${tz}`;
    return await axios({
      url: uri,
      method: 'GET',
      // params: params,
      responseType: 'blob'
    })
      // .post(uri, params)
      .then(response => {
        return response ?? false
      }).catch(error => {
        GlobalService.handleError(error, 'DOWNLOAD ACCIDENT INCIDENT REPORT TEMPLATE SERVICE')
        return false
      })
  },

  async downloadVesselParticular(vessel_id, params) {
    const date_now = new Date();
    const ts = parseInt(date_now.getTime() / 1000) // timestamp
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone; // timezone

    const queryString = UrlService.setQueryString(params)+ `&ts=${ts}&tz=${tz}`;

    return await axios({
      url: VESSEL_DETAIL + `/${vessel_id}` + queryString,
      method: 'GET',
      responseType: 'blob'
    })
      .then(response => {
        return response ?? false
      }).catch(error => {
        GlobalService.handleError(error, 'DOWNLOAD ACCIDENT INCIDENT REPORT TEMPLATE SERVICE')
        return false
      })
  },

  async downloadPscReport(params, type = 'xlsx') {
    const date_now = new Date();
    const ts = parseInt(date_now.getTime() / 1000) // timestamp
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone; // timezone

    const uri = DOWNLOAD_PSC_REPORT.replace('{report_id}', params.id) + `?type=${type}&ts=${ts}&tz=${tz}`;
    return await axios({
      url: uri,
      method: 'GET',
      // params: params,
      responseType: 'blob'
    }).then(response => {
      return response ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'DOWNLOAD PSC INSPECTION REPORT SERVICE')
      return false
    })
  },

  async downloadPscReportTemplate(vesselId, deficiencyCount = 1) {
    const date_now = new Date();
    const ts = parseInt(date_now.getTime() / 1000) // timestamp
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone; // timezone

    // const uri = DOWNLOAD_PSC_REPORT_TEMPLATE.replace('{vessel_id}', vesselId) + `?ts=${ts}&tz=${tz}`;
    const uri = DOWNLOAD_PSC_REPORT_TEMPLATE.replace('{vessel_id}', vesselId);

    const params = { deficiencyCount, ts, tz };

    return await axios({
      url: uri,
      method: 'GET',
      params: params,
      responseType: 'blob'
    }).then(response => {
      return response;
    }).catch(error => {
      const _error = GlobalService.errorCatcher(error);

      AlertService.errorAlert(_error.message, 'Psc Inspection Report Template');

      return _error;
      // GlobalService.handleError(error, 'DOWNLOAD PSC INSPECTION REPORT TEMPLATE SERVICE')
      // return error
    });


  },
  async getContactList(params){
    return await axios({
      url:FLEET_CONTACT_LIST,
      method: 'GET',
      params: params,
      responseType: 'blob'
    }).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET CONTACT LIST SERVICE')
      return false
    })
  },

  async getContactListCreatedDate(){
    return await axios({
      url:FLEET_CONTACT_LIST_DATE,
      method: 'GET',
    }).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET CONTACT LIST DATE SERVICE')
      return false
    })
  },



  async getFleetContactLists() {
    return await axios({
      url:GET_FLEET_CONTACT_LISTS,
      method: 'GET'
    }).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET CONTACT LISTS SERVICE')
      return false
    })
  },

  async deleteFleetContactList(params) {
      return await axios({
          url:DELETE_FLEET_CONTACT_LIST_BY_NAME,
          method: 'POST',
          data: params
      }).then(response => {
          return response.data ?? false
      }).catch(error => {
          GlobalService.handleError(error, 'DELETE CONTACT LIST SERVICE')
          return false
      })
  },

  async uploadContactList(params) {
      return await axios.post(UPLOAD_FLEET_CONTACT_LIST, params).then(response => {
          return response.data;
      }).catch(error => {
          GlobalService.handleError(error, 'UPLOAD CONTACT LIST SERVICE')
          return error.response.data;
      })
  },

  async getAccidentIncidentByEvent(params){
    var queryString=UrlService.setQueryString(params);
    return await axios({
      url:GET_ACCIDENT_INCIDENT_BY_EVENT+queryString,
      method: 'GET',
    }).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET CONTACT LIST SERVICE')
      return false
    })
  },

  async downloadCrewExperienceList(params) {
    const queryString= UrlService.setQueryString(params);

    const uri = DOWNLOAD_CREW_EXPERIENCE_LIST + queryString;
    return await axios({
      url: uri,
      method: 'GET',
      // params: params,
      responseType: 'blob'
    }).then(response => {
      return response ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'DOWNLOAD PSC INSPECTION REPORT SERVICE')
      return false
    })
  },
}

